<template>
  <div class="container mx-auto h-full w-full py-4">
    <app-modal-payment-link-detail
      :show="showModalDetail"
      @close="showModalDetail = false"
      :payment_link="selectPaymentLink"
      @update:payment_link="selectPaymentLink"
    />
    <app-table
      :apiResponse="apiPaginationResponse.data ?? []"
      :loading="apiPaginationLoading"
      :filters="[
        {
          key: 'filter[title]',
          type: 'text',
          placeholder: $t('payment.link.filter.title'),
          value: '',
        },
        {
          key: 'filter[email]',
          type: 'text',
          placeholder: $t('payment.link.filter.email'),
          value: '',
        },
        {
          key: 'filter[currency]',
          type: 'select',
          placeholder: $t('payment.link.filter.currency'),
          value: '',
          items: [
            {
              id: 'MYR',
              name: 'MYR',
            },
            {
              id: 'SGD',
              name: 'SGD',
            },
            {
              id: 'BAHT',
              name: 'BAHT',
            },
            {
              id: 'USD',
              name: 'USD',
            },
            {
              id: 'IDR',
              name: 'IDR',
            },
          ],
        },
        {
          key: 'filter[amount]',
          type: 'number',
          placeholder: $t('payment.link.filter.amount'),
          value: '',
        },
        {
          key: 'filter[payment_link_id]',
          type: 'text',
          placeholder: $t('payment.link.filter.payment_link_id'),
          value: '',
        },
      //  {
      //    key: 'filter[attempt_id]',
      //    type: 'text',
      //    placeholder: $t('payment.link.filter.payment_attempt_id'),
      //    value: '',
      //  },
        {
          key: 'filter[created_at_between]',
          type: 'date',
          value: '',
        },
      ]"
      @params-changed="paramsChanged"
      @on-item-click="onItemClick"
      :showEmptyButton="false"
    >
      <template v-slot:title>
        <div class="flex space-x-3">
          <app-icon-outline
            @click="goToBack"
            name="ArrowLeftIcon"
            class="w-5 cursor-pointer"
          />
          <p class="font-medium">
            {{ $t("wallet.transaction.processed-payment-link") }}
          </p>
        </div>
      </template>
      <template v-slot:button>
        <app-button
          width="w-auto"
          v-if="!dataEmpty"
          :showf70Icon="false"
          @click="downloadCSV"
        >
          {{ $t("general.download_csv") }}
        </app-button>
      </template>
      <template v-slot:header>
        <th class="font-bold">{{ $t("payment.link.header.title") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.amount") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.charges") }}</th>
        <th class="font-bold">{{ $t("wallet.overview.header.net_amount") }}</th>
        <th class="font-bold">{{ $t("payment.link.header.status") }}</th>
        <th class="font-bold">{{ $t("payment.link.header.system_status") }}</th>
        <th class="font-bold">{{ $t("payment.link.header.email") }}</th>
        <th class="font-bold">{{ $t("payment.link.header.date") }}</th>
      </template>
      <template v-slot:body="slotData">
        <td>
          {{ slotData.model.title }}
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.amount ?? 0.0
              )
            }}
          </p>
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                getPaymentAmount(slotData.model.payments ?? []).charges
              )
            }}
          </p>
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                getPaymentAmount(slotData.model.payments ?? []).net_amount
              )
            }}
          </p>
        </td>
        <td class="flex place-items-center space-x-2 justify-between">
          <app-badge
            :status="slotData.model.paid ? 'success' : 'light'"
            class="w-16 text-xs"
          >
            {{
              slotData.model.paid
                ? $t("payment.detail.paid")
                : $t("payment.detail.unpaid")
            }}
          </app-badge>
        </td>
        <td>
          <app-badge
            v-if="slotData.model.settlement != null"
            :status="'success'"
            class="w-16 text-xs"
          >
            {{ $t("payment.detail.processed") }}
          </app-badge>
          <p v-else>-</p>
        </td>
        <td>
          {{ slotData.model.email }}
        </td>
        <td>
          {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
        </td>
      </template>
      <template v-slot:footer>
        <tr>
          <td class="font-bold">{{ $t("wallet.transaction.total") }}</td>
          <td>&nbsp;</td>
          <td class="font-bold">
            {{
              $formats.currency(
                apiPaginationResponse?.data?.summary?.currency,
                apiPaginationResponse?.data?.summary?.amount ?? 0
              )
            }}
          </td>
          <td class="font-bold">
            {{
              $formats.currency(
                apiPaginationResponse?.data?.summary?.currency,
                apiPaginationResponse?.data?.summary?.charge ?? 0
              )
            }}
          </td>
          <td class="font-bold" colspan="7">
            {{
              $formats.currency(
                apiPaginationResponse?.data?.summary?.currency,
                apiPaginationResponse?.data?.summary?.net ?? 0
              )
            }}
          </td>
        </tr>
      </template>
    </app-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryParams: "",
      showModalDetail: false,
      selectPaymentLink: null,
    };
  },

  async mounted() {
    await this.retrieveSettlementIndex();

    if (this.$route.query.id) {
      this.showModalPaymentLinkById(this.$route.query.id);
    }
  },

  computed: {
    apiPaginationResponse() {
      let data = this.$store.getters["walletStore/apiPaginationResponse"];
      console.log(data);
      return data;
    },
    dataEmpty() {
      return (
        this.$lodash.isEmpty(this.apiPaginationResponse?.data?.data) ?? null
      );
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },
    errors() {
      return this.$store.getters["walletStore/errors"];
    },
  },

  methods: {
    goToBack() {
      this.$router.go(-1);
    },
    async downloadCSV() {
      let downloadCSVResult = await this.$store.dispatch(
        "walletStore/downloadSettlementCSV",
        {
          settlement_id: this.$route.params.settlement_id,
          business_id: this.$route.params.business_id,
          queryParams: this.queryParams,
        }
      );

      if (downloadCSVResult.data) {
        window.location.href = downloadCSVResult.data;
      }
    },
    async retrieveSettlementIndex() {
      return await this.$store.dispatch("walletStore/retrieveSettlementIndex", {
        settlement_id: this.$route.params.settlement_id,
        business_id: this.$route.params.business_id,
        queryParams: this.queryParams,
      });
    },
    async showModalPaymentLinkById(id) {
      let payment_link = this.apiPaginationResponse?.data?.data?.find(
        (payment_link) => payment_link._id == id
      );

      if (this.$lodash.isEmpty(payment_link)) {
        let result = await this.$store.dispatch("paymentStore/getPaymentLink", {
          payment_link_id: id,
        });
        payment_link = result?.data;
      }

      if (!this.$lodash.isEmpty(payment_link)) {
        this.selectPaymentLink = payment_link;
        this.showModalDetail = true;
      }
    },
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) return;

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },

    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.retrieveSettlementIndex();
    },

    // filterFormatDate(key) {
    //   let date = this.filters["filter[" + key + "]"];

    //   let start = date?.start_date;
    //   let end = date?.end_date;

    //   if (date) {
    //     this.filters["filter[" + key + "]"] = start + "," + end;
    //   }
    // },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPaymentLink = data;
    },

    getPaymentAmount(payments) {
      var paymentAmount = {
        net_amount: null,
        amount: null,
        charges: null,
      };

      payments.forEach((payment) => {
        paymentAmount.net_amount += payment.settlement_amount;
        paymentAmount.amount += payment.amount;
        paymentAmount.charges +=
          (payment.amount - payment.settlement_amount) * -1;
      });

      return paymentAmount;
    },
  },
};
</script>
